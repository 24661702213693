import React from 'react';

const CurvedHorizontalDivider = ({
	width = '1122',
	height = '62',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 1122 62" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1121 55.8505C1022.75 55.8505 973.632 77.2091 973.632 32.9287C973.632 -11.3517 1016.2 -3.49378 1007.72 19.9054C994.684 55.8504 846.313 55.8505 721 55.8505H1" stroke="#46460E" strokeOpacity="0.2" />
	</svg>

);

export default CurvedHorizontalDivider;
